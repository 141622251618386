import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

// Initialisez Stripe avec votre clé publique (pas la clé secrète)
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Verification = () => {
  const location = useLocation();
  const [paymentStatus, setPaymentStatus] = useState('En cours de vérification...');
  const [error, setError] = useState('');
  const [paiementValide, setPaiementValide] = useState(false);

  useEffect(() => {
    // Cette fonction devrait être appelée pour vérifier le statut du paiement
    const verifyPayment = async () => {
      const queryParams = new URLSearchParams(location.search);
      const clientSecret = queryParams.get('payment_intent_client_secret');

      if (!clientSecret) {
        setError('Informations de paiement manquantes.');
        setPaymentStatus('Paiement échoué. Veuillez réessayer.');
        return;
      }

      const stripe = await stripePromise;
      const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

      if (paymentIntent.status === 'succeeded') {
        setPaymentStatus('Paiement réussi ! Merci pour votre achat.');
        setPaiementValide(true);
      } else {
        setPaymentStatus('Paiement échoué. Veuillez réessayer.');
        setError(paymentIntent.last_payment_error?.message || 'Une erreur est survenue lors du processus de paiement.');
      }
    };

    verifyPayment();
  }, [location.search]);

  return (
    <div className="verification-container">
      <h2>Statut du Paiement</h2>
      <p>{paymentStatus}</p>
      {error && <p>Erreur : {error}</p>}
      {paiementValide && (
        <p>
          Vous aurez accès au cours par ici : <Link to="/listeCours" style={{ textDecoration: 'none' }}> liste des cours. </Link>
        </p>
      )}
    </div>
  );
};

export default Verification;
