import { useSpring, animated } from 'react-spring';

function TriangleArrowWithIndent() {
  const arrowAnimation = useSpring({
    from: { opacity: 0, transform: 'translateY(0px)' }, // Démarre invisible
    to: async (next) => {
      await next({ opacity: 0, transform: 'translateY(0px)' });
      await new Promise(resolve => setTimeout(resolve, 4000)); // Attente de 4 secondes
      await next({ opacity: 1, transform: 'translateY(0px)' }); // Apparaît sans bouger
      await next({ opacity: 0, transform: 'translateY(60px)' }); // Se déplace vers le bas et disparaît
    },
    config: { duration: 2000 }
  });

  const style = {
    clipPath: "polygon(0 49%, 50% 57%, 100% 49%, 50% 100%)",
    border: "1px solid white",
    backgroundColor: 'white',
    width: "100px",
    height: "100px",
    zIndex: '1000'
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: '50%',
    bottom: '15%',
    transform: 'translateX(-50%)',
    width: '100%',
    height: '100px'
  };

  return (
    <div style={containerStyle}>
      <animated.div style={{ ...arrowAnimation, ...style }} />
    </div>
  );
}

export default TriangleArrowWithIndent;
