import React, { useEffect, useRef } from 'react';

const YouTubeVideo = ({ videoId }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    let player;
    const onYouTubeIframeAPIReady = () => {
      // Cette vérification assure que containerRef.current est défini
      if (containerRef.current) {
        player = new window.YT.Player(containerRef.current, {
          height: '100%',
          width: '100%',
          videoId: videoId,
          playerVars: {
            autoplay: 0, // Ne pas démarrer automatiquement
            modestbranding: 1, // Minimiser le branding YouTube
            rel: 0, // Ne pas montrer de vidéos relatives à la fin
            controls: 1, // Montrer les contrôles
            showinfo: 0, // Ne pas montrer les infos de vidéo
            loop: 1, // Boucler la vidéo
            fs: 0, // Cacher le bouton de plein écran
            cc_load_policy: 0, // Cacher les sous-titres
            iv_load_policy: 3, // Cacher les annotations vidéo
            autohide: 1 // Ne pas cacher automatiquement la barre de contrôle vidéo
          },
          events: {
            'onReady': onPlayerReady,
          }
        });
      }
    };

    const onPlayerReady = (event) => {
      // Gérer l'état prêt du lecteur ici, si nécessaire
    };

    // Insère le script de l'API YouTube IFrame Player si nécessaire
    if (!window.YT) { // Charge l'API si elle n'est pas déjà présente
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      
      // Définir cette fonction globalement pour que l'API YouTube puisse l'appeler
      window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
    } else {
      onYouTubeIframeAPIReady();
    }

    return () => {
      if (player && player.destroy) {
        player.destroy();
      }
    };
  }, [videoId]);

  return (
    <div className="short-video-container" ref={containerRef}>
      {/* Le lecteur YouTube sera injecté ici par l'API */}
    </div>
  );
};

export default YouTubeVideo;