import React, { useState, useEffect, useRef } from 'react';
import { useTrail, animated } from '@react-spring/web';

export const useAnimatedTrail = (children, config = { mass: 5, tension: 2000, friction: 200 }) => {
  const [triggerAnimation, setTriggerAnimation] = useState(false);
  const ref = useRef(null);

  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    ...config,
    opacity: triggerAnimation ? 1 : 0,
    x: triggerAnimation ? 0 : 20,
    height: triggerAnimation ? 110 : 0,
    from: { opacity: 0, x: 20, height: 0 },
  });

  useEffect(() => {
    const handleScroll = () => {
      if (!ref.current) return;
      
      const { top, bottom } = ref.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      
      // Ajustement pour exiger un défilement de 110px supplémentaires au-delà de la hauteur de l'élément
      const isFullyVisibleWithExtra = top < windowHeight - 110 && bottom > 110;
    
      setTriggerAnimation(isFullyVisibleWithExtra);
    };    

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Exécute immédiatement pour vérifier l'état initial

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const animatedItems = trail.map((style, index) => (
    <animated.div
      key={index}
      className="trailsText"
      style={{
        ...style,
        transform: style.x.to(x => `translate3d(${x}px,0,0)`),
        height: style.height.to(height => `${height}px`),
        opacity: style.opacity,
      }}
    >{items[index]}</animated.div>
  ));

  return { animatedItems, ref };
};
