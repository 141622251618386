import { useSpring } from 'react-spring';

const cloudImages = [
  '/pictures/nuage1.png',
  '/pictures/nuage2.png',
  '/pictures/nuage3.png'
];

const useCloudAnimation = () => {
  // Cette fonction sélectionne aléatoirement une image de nuage
  const chooseRandomCloudImage = () => {
    const randomIndex = Math.floor(Math.random() * cloudImages.length);
    return cloudImages[randomIndex];
  };

  // Ajustement de la taille du nuage en fonction de la largeur de l'écran
  const screenWidth = window.innerWidth;
  let scale = screenWidth / 1866; // Ajustement basé sur la largeur originale du nuage
  scale = Math.max(0.5, Math.min(scale, 1.0)); // Limiter la taille du nuage entre 0.5x et 1.5x

  const cloudWidthAdjusted = 1866 * scale; // Largeur du nuage après ajustement
  const randomHorizontalStart = Math.random() * (screenWidth - cloudWidthAdjusted);

  // Utiliser 'chooseRandomCloudImage' pour définir l'image de fond initiale du nuage
  const [props, set] = useSpring(() => ({
    from: {
      transform: `translate3d(${randomHorizontalStart}px,-100%,0) scale(${scale})`,
      opacity: 1,
      backgroundImage: `url(${chooseRandomCloudImage()})`, 
    },
    to: async (next) => {
      await next({ transform: `translate3d(${randomHorizontalStart}px,80vh,0) scale(${scale})`, opacity: 0.8 });
      await next({ transform: `translate3d(${randomHorizontalStart}px,100vh,0) scale(${scale})`, opacity: 0 });
      await next({ opacity: 0, backgroundImage: `url(${chooseRandomCloudImage()})` }); // Prêt pour la prochaine animation
    },
    reset: true,
    config: { duration: 5000 + Math.random() * 7000 }, 
  }));

  const triggerAnimation = () => {
    set({ reset: true });
  };

  return { props, triggerAnimation };
};

export default useCloudAnimation;
