import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext.js'; 
import { db } from './donnees/firebase.js'; 
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useUI } from './UIContext.js';

function ValidationEmail() {
  const navigate = useNavigate();
  const { currentUser } = useAuth(); 
  const { openLoginModal } = useUI();

  useEffect(() => {
    const updateEmailVerified = async () => {
      if (!currentUser) {
        openLoginModal();
        toast.info("Veuillez-vous connecter, puis recharger la page (ou réutiliser le lien).");
      }
      else {
        const userDocRef = doc(db, "utilisateurs", currentUser.uid);
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists() && docSnap.data().emailVerified) {
          toast.info("Votre email a déjà été vérifié.");
          navigate('/');
          return;
        }

        try {
          const userDocRef = doc(db, "utilisateurs", currentUser.uid);
          await setDoc(userDocRef, { emailVerified: true }, { merge: true });
      
          // Si la mise à jour réussit
          toast.success("Votre email a été vérifié avec succès !");
          navigate('/'); // Redirige l'utilisateur vers la page d'accueil ou autre
        } catch (error) {
          console.error("Erreur lors de la mise à jour de Firestore :", error);
          toast.error("Une erreur est survenue lors de la vérification.");
        }
      }
    };

    updateEmailVerified();
  }, [currentUser, navigate, openLoginModal]);

  return (
    <div className="content">
      <p>Vérification de l'email en cours...</p>
    </div>
  );
}

export default ValidationEmail;
