import { animated } from '@react-spring/web';
import React from 'react';
import { Link } from 'react-router-dom';
import useAnimatedLine from '../animations/animatedLine.js';


function Chapitre8Saimer() {

  const [animationStyle1, ref1] = useAnimatedLine();
  const [animationStyle2, ref2] = useAnimatedLine();
  const [animationStyle3, ref3] = useAnimatedLine();
  const [animationStyle4, ref4] = useAnimatedLine();
  const [animationStyle5, ref5] = useAnimatedLine();
  const [animationStyle6, ref6] = useAnimatedLine();

  return (
      <div className="content">

        <h1 className='chapitre'>Chapitre 8 : La dépression</h1>

        <p>
          Parfois l'Homme est tellement habitué à ne plus savoir s'écouter qu'il sombre dans des idées fixes, des habitudes, des agissements désalignés qui le font souffrir.
        </p>
        <p>
          Cela peut ne pas être ressenti au début, mais à force, il commencera à se sentir de moins en moins confortable, de moins en moins bien avec la façon dont il vit les choses.
        </p>

        <animated.div className="animated-line" style={animationStyle1} ref={ref1}>
          <p className="animatable">
          La dépression est un état qui peut survenir à force d'être dans ce désalignement. Elle peut de même venir par des états émotionnels qu'on n'a pas pu gérer, suite à des chocs, des évènements difficiles.
          </p>
        </animated.div>
        
        <p>
          Personnellement, j'ai vécu une longue dépression il y a quelques années. Elle était dû au fait que je ne me trouvais pas dans ma vie. Je ne reconnectais plus à qui j'étais. À quel moment on peut éprouver un manque de sens ?
          Quand notamment on ne parvient plus à exprimer le naturel en nous-même. Il n'est pas nécessaire d'aller au bout du monde ou d'essayer d'aider les autres sans joie. Tout ce dont on a besoin pour commencer est d'ouvrir notre cœur à nous-même.
          S'exprimer et s'accepter est essentiel. À l'inverse, résister à qui l'on est, est la plus grande des souffrances.<br /><br />
          La dépression est liée à l'émotion qu'est la tristesse. Et effectivement, elle est aussi liée à un appel à mourir de quelque chose (accepter une fin) : une rupture amoureuse, une fin d'activité professionnelle; ça peut être aussi le deuil d'une personne, ou encore la fin d'une période de vie...<br />
          Suite à cela, <span className='underline'>c'est notre mental qui n'arrive plus à traiter l'information</span>. On n'a plus la capacité mentale à la digérer car il y a eu un choc ou un cumul trop lourd.
        </p>

        <p>
          Ainsi, malheureusement, les remèdes typiques occidentaux qui influent sur notre mental dans l'objectif de guérir la dépression sont, en général, vains, car c'est une problématique émotionnelle, et liée à la conscience. Donc, ces remèdes ou certaines thérapies qui ne font appel qu'au mental ne nous aideront pas. Il sera nécessaire d'appréhender les choses différemment car, dans la dépression, il y a un processus de <b>conscientisation et de digestion</b>. Il faudra apporter d'autres clés, faire le lien entre notre mental et ce profond changement intérieur.
        </p>

        <div className="clouds-section">
          <div className="clouds-begin"></div>
          <div className="clouds-content">
          {/* nuages */}

            <animated.div className="animated-line" style={animationStyle2} ref={ref2}>
            <p className="animatable">
              La dépression est un terme qui vient aussi du domaine météorologique. 
              Le principe est qu'il y a des courants d'air et de vapeur à cause d'une dépression (aspiration dans une zone à plus basse pression, un peu comme un “vide”). Ce courant aura tendance à être ascendant, et la vapeur de l'air se condensera et formera des nuages et de la pluie. 
              <br /><br />
              Symboliquement, pour une personne, on peut relier ça au monde émotionnel (lié à l'eau). Le vide d'une dépression est pour elle la cause mentale et émotionnelle qui va la rendre triste et apathique par moment. Il sera inutile d'essayer de chercher une cause uniquement extérieure car ce vide est intérieur.
            </p>
            </animated.div>

            <animated.div className="animated-line" style={animationStyle3} ref={ref3}>
              <p className="animatable">
              La dépression, c'est un peu comme un sol qui s'affaisse. Ce qu'on a construit, ce à quoi on était attaché part en lambeau. Et plus on continue à vouloir s'y accrocher, alors que la vie nous dit : “c'est fini”, plus on prolonge et on aggrave celle-ci. <br /><br />
              On ne laisse pas encore la place au nouveau, ce qui nous place dans un entre-deux : entre quelque chose qui meurt et un nouveau qu'on n'accueille pas.
              </p>
            </animated.div>
          </div>
          <div className="clouds-end"></div>
        </div>
        {/* Fin nuages section */}

        <p>
          Le suicide : on s'identifie à la partie de notre être qui change et qui n'est plus ce qu'il était. Par conséquent, à travers une partie d'Ego illusoire, on s'attache à l'identité qui veut mourir : notre âme ne souhaite plus vivre au travers. De ce fait, ce qu'il peut se passer, c'est que la personne confonde ce besoin vital avec la mort de son propre corps : alors que la fin souhaitée est bien celle de cette ancienne identité.
        </p>

        <p>
          Malheureusement, dans une société qui attache beaucoup d'importance à l'extérieur, l'accompagnement des personnes ayant des volontés de suicide n'est pas pris assez au sérieux. Et la compréhension du fonctionnement de la dépression et des pensées suicidaires n'est pas encore quelque chose de véritablement établie en dehors des organismes spécialisés.
          <br />
          C'est une mise à l'épreuve qui touche au moins 20% de la population au moins une fois dans sa vie. Et de plus, elle atteint plus de monde de nos jours. Ceci pour différentes raisons : la conscience humaine qui se développe toujours fortement, et les problématiques du fonctionnement sociétal qui privilégie les apparats et la division : <b>compétition, jugement, isolement</b>.
        </p>

        <animated.div className="animated-line" style={animationStyle4} ref={ref4}>
          <p className="animatable">
            En soi, la dépression est une vraie porte qui nous dirige vers une <span className='gold'>renaissance</span>. <br />
            C'est un “nettoyage” émotionnel qui va nous pousser à lâcher l'ancien et à accepter le changement.
          </p>
        </animated.div>

        <p>
          Ce qui est bon dans une phase de déprime, que ce soit profond ou pas :
        </p>

        <animated.div className="animated-line" style={animationStyle5} ref={ref5}>
          <p className="animatable">
            <b>Se donner le temps :</b> c'est plutôt bateau, mais il est bon de se rappeler que rien n'est immuable, demain sera différent d'aujourd'hui. Ce qui est triste et difficile aujourd'hui peut devenir léger et lumineux demain. 
          </p>
        </animated.div>

        <animated.div className="animated-line" style={animationStyle6} ref={ref6}>
          <p className="animatable">
          <b>Savoir s'ancrer dans le présent :</b> si la peine s'ancre à travers des pensées dans le temporel (passé/futur), le présent, lui, reste simple : Eckhart Tolle nous partage de très bonnes réflexions à propos :
          “La plupart des humains ne sont jamais pleinement présents dans le moment présent, parce qu'inconsciemment ils croient que le moment suivant sera certainement plus important que celui-ci. Mais alors vous ratez votre vie entière, qui n'est plus jamais le moment présent.”
          </p>
        </animated.div>

        <p>
          Et c'est à travers ce manque d'ancrage au présent que souvent une dépression, une angoisse, une tristesse, prend plus de place qu'elle n'aurait dû prendre car le mental tourne en boucle, la simplicité de la vie n'est plus perçue, ainsi que les lâcher-prises possibles, les petites joies, ...
          <br /><br />
          “Je crois que ce sont les petites choses, les gestes quotidiens des gens ordinaires qui nous préservent du mal… de simples actes de bonté et d'amour ! Pourquoi Bilbon Sacquet ? Peut être par ce que j'ai peur et  qu'il me donne du courage !” Le Hobbit : un voyage inattendu, Gandalf.<br /><br /><br />
        </p>

        <p>
          <span className='bold'>Let's go pour un exercice que vous pouvez faire au quotidien :</span>
          <br />
          On ne s'en rend pas compte mais nous avons des tensions corporelles tout au long d'une journée à cause du stress même sans s'en rendre compte.
          L'objectif va alors être de scanner son corps pour prendre conscience de là où elles se trouvent. Si vous sentez que vous respirez moins bien, que vous êtes un peu anxieux.se, ou bien que vous avez une certaine charge mentale, posez vous une minute, écoutez votre corps et donc ressentez-le.
          Il pourrait y avoir des tensions au niveau du front par exemple. Détendez cette partie. Vous pouvez avoir aussi les poignets tendus, détendez-les.
          Quelque soit la partie tendue (coudes, plexus, cou, tempes, ...), faites en sorte de la détendre, par exemple si c'est le plexus solaire, faites un exercice de respiration (inspiration 4 secondes, retenue 4-6 secondes, expiration...). <br /><br /><br />
        </p>

        <div className="button">
          <Link to="/formationSaimer" className="sommaire-button">Retour au sommaire</Link>
        </div>

      </div>
  );
}

export default Chapitre8Saimer;
