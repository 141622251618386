import React from 'react';
import { Link } from 'react-router-dom';
import { animated } from '@react-spring/web';
import useAnimatedLine from '../animations/animatedLine.js';
import LoadingGif from '../animations/loadingGif.js';

function IntroductionSaimer() {
  const [animationStyle1, ref1] = useAnimatedLine();
  const [animationStyle2, ref2] = useAnimatedLine();
  const [animationStyle3, ref3] = useAnimatedLine();

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    overflow: 'hidden'
  };

  const iframeStyle = {
    border: 'none',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    minHeight: '620px', 
    minWidth: '540px',
    flexShrink: 0
  };

  return (
      <div className="content">

        <h1 className='chapitre'>Introduction</h1>

        <p>Ce cours a été créé dans l'objectif d'aborder le sujet de l'amour de soi et de te transmettre des clés de conscience et des exercices pour être en phase avec toi-même et créer ou développer un lien fort avec ton monde intérieur. 
        Lorsque s'aimer devient une priorité dans notre vie, tout change. Car l'amour de soi est inéluctablement l'amour du monde.</p>
        <p>Sans cela, la vie est tout simplement plus complexe et houleuse.</p>

        <p>
        Tu vas avoir l'occasion de découvrir ici des sujets et des connaissances variés. Il y aura des parties plus théoriques et d'autres plus pratiques. 
        </p>
        <p><br />Durant les différents chapitres, fait défiler la page pour que le contenu apparaisse. <br />
        Sur le coin en haut à gauche, tu peux remonter en haut de page à l'aide du bouton qui apparaît au survol de la souris (touche le coin sur smartphone).<br />
        C'est donc parti pour ce programme pour mieux se connaître et comprendre les rouages de notre mental et de notre cœur.
        <br /><br /> <img className="img_fleche" src="/pictures/fleche_b.png" alt="cours suite" /> </p>

        <animated.div className="animated-line" style={animationStyle1} ref={ref1}>
          <p className="animatable">Nous ne sommes pas ici pour nous changer. Nous sommes infiniment <span className="gold">talentueux</span> en faisant une expérience humaine. Nous sommes ici pour explorer. 
            Ce n'est <span className="underline">jamais toi que tu dois changer</span>, ce sont tes croyances, ta manière de penser et de percevoir les choses.</p>
        </animated.div>

        <animated.div className="animated-line" style={animationStyle2} ref={ref2}>
          <img src="/pictures/papillon.gif" alt="papillon croissance personnel" className="image animatable" />
        </animated.div>

        <p>Ce cours a été conçu dans le but de te guider et t'inspirer à voir la vie d'une nouvelle manière : une perception qui changera ta façon de vivre les différents évènements de ta vie. </p>
        <p> 
          La thématique de l'amour de soi (et des autres) est une base, une brique même je dirais, à notre propre vie.<br /> 
        </p>

        <div className="perso_talking">
          <LoadingGif
            src="/pictures/moiteacher1.gif"
            placeholder="/pictures/moiteacher1.png"
            alt="cours développement personnel"
          />
        </div>
        
        <animated.div className="animated-line" style={animationStyle3} ref={ref3}>
          <p className="animatable">
           Selon moi, c'est quelque chose qui devrait, de base, être enseigné à l'école. <br />Mais ça n'est pas le cas, ainsi, je vous partage ma vision des choses au travers de ces cours, et d'une manière bien spécifique : de sorte à faire émerger les déclics qui vous permettront de nourrir les meilleurs pensées et les meilleurs comportements pour vous-même.
          </p>
        </animated.div>

        <p><br /><br />Je vous propose non pas de vous apprendre des choses - un peu peut-être - mais surtout de désapprendre, et d'arriver à se relier à sa “source”.</p>
        
        <p>S'aimer n'est jamais, en aucun cas, un acte égotique. Il n'est, selon ma définition, pas possible de devenir trop auto-centré en s'aimant plus. <br />
        Si c'est le cas, c'est qu'on ne parle pas d'amour, mais d'un attachement à une image redoré de soi. L'attachement à l'égo est plutôt l'inverse de s'aimer. C'est ce qui peut faire devenir arrogant et orgueilleux, ce qui est relié au fait de se distancer des autres et rentrer dans une lutte intérieure ainsi que d'être sous un voile fort (déni). <br /><br /></p>
        
        
        <p>Ainsi, on pourra voir que ce fameux ego est quelque chose de naturel et que cela peut devenir négatif qu'à partir d'une rupture par rapport à notre Être véritable.  <br /><br /></p>
      
        <p>Si tu es prêt à te reconnecter à toi-même et adopter une conscience avec laquelle tu ne te dévalorises plus à cause de jugements inconscients ou de croyances sur qui tu es, alors tu es au bon endroit, et je suis là pour t'accompagner.<br /><br /></p>

        <p>
          Juste avant d'aller sur les prochains chapitres, j'aimerais te parler de ce que le fait de lire plus lentement permet.
          Dans un monde où le mental devient stimulé constamment avec des informations qui nous parviennent très rapidement, on finit par s'habituer à être dans ce rythme, adoptant une façon inconsciente de recevoir les infos.<br />
          On fait défiler vite et on zappe beaucoup de choses car au fond si on s'arrêtait sur chaque élément, on en aurait pas
          finit de chercher ce qu'on souhaite voir.
          Le truc, c'est que cette façon de lire est devenue une habitude et l'écriture, et tout contenu même audio, devient un language mental superficiel.<br /><br />
          Voici un extrait d'une vidéo Youtube "How slow reading can change your brain" de "DamiLee" :<br />
          "La plupart des études semblent s'accorder sur le fait que la lecture implique au moins deux étapes : voir le mot et le considérer en reconstruisant le code des signes à travers une chaîne de connexions dans le cerveau et ensuite, en fonction de qui nous sommes, nous déchiffrons le texte avec émotion, sensibilité physique, intuition, connaissance ou âme. D'une certaine manière, c'est comme expérimenter l'espace. Ce n'est pas juste nos sens visuels mais cela intègre aussi la mémoire et le noyau de notre être."
          <br /><br />
          En fait, plus nous lisons rapidement moins on sollicite la réceptivité émotionnelle et sensible de nos perceptions. C'est-à-dire : plus nous sommes dans une lecture mental déconnectée de cette profondeur comme DamiLee nous le fait remarquer (cet espace expérimenté). Donc, ce sont autant de compréhensions profondes desquelles on passe à côté plus nous sommes en mode automatique.<br /><br />
        </p>

        <p>
          Pour récapituler cette introduction, faisons un petit test pour intégrer certains points essentiels :<br />
        </p>

        <div style={containerStyle}>
          <iframe
            src="/compoH5P/citations_concepts.html"
            style={iframeStyle}
            title="drag&drop flowgraines citations"
          ></iframe>
        </div>
        
        <div className="button">
          <Link to="/formationSaimer" className="sommaire-button">Retour au sommaire</Link> 
        </div>
      </div>
  );
}

export default IntroductionSaimer;
