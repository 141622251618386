import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from './donnees/firebase.js'; 
import CheckoutForm from './CheckoutForm.js'; 

const StripeCheckout = ({ onSuccessfulCheckout, programme, onClose }) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm onSuccessfulCheckout={onSuccessfulCheckout} programme={programme} onClose={onClose} />
  </Elements>
);

export default StripeCheckout;
