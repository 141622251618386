import React, { useMemo } from 'react';

const RandomBackgroundWrapper = ({ children }) => {
  const randomImages = useMemo(() => {
    const images = [];
    const numberOfImages = 7;
    const originalWidth = 320;
    const originalHeight = 360;
    const aspectRatio = originalHeight / originalWidth;

    for (let i = 0; i < numberOfImages; i++) {
      // Générer une largeur aléatoire entre 50px et 150px
      const randomWidth = Math.floor(Math.random() * (160 - 70 + 1)) + 70;
      const randomHeight = randomWidth * aspectRatio;

      const style = {
        position: 'absolute',
        left: `${Math.random() * 100}%`,
        top: `${Math.random() * 100}%`,
        transform: 'translate(-50%, -50%)',
        opacity: 0.3,
        width: `${randomWidth}px`,
        height: `${randomHeight}px`,
        backgroundImage: 'url("/pictures/etoile.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        pointerEvents: 'none',
      };
      images.push(<div key={i} style={style} />);
    }
    return images;
  }, []);

  return (
    <>
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden',
        pointerEvents: 'none',
      }}>
        {randomImages}
      </div>
      {children}
    </>
  );
};

export default RandomBackgroundWrapper;