import React from 'react';

function Cgu() {

  return (
      <div className="content">
        <h1>Conditions Générales d'Utilisation (CGU)</h1>

        <h2>1. Objet</h2>
        <p>Les présentes conditions régissent l'utilisation du site flowgraines.fr. Le site est accessible à tout utilisateur ayant accès à internet. L'accès et l'utilisation du site impliquent l'acceptation sans réserve des présentes Conditions Générales d'Utilisation par l'utilisateur.</p>

        <h2>2. Propriété intellectuelle</h2>
        <p>Tout le contenu publié sur le site flowgraines.fr, incluant, sans limitation, les textes, graphiques, logos, images, et vidéos, est la propriété exclusive d'Aurélien Bessonnet ou est utilisé avec l'autorisation des détenteurs de droits. Toute reproduction, distribution, modification, adaptation, ou publication de ces éléments est strictement interdite sans l'accord exprès par écrit d'Aurélien Bessonnet.</p>

        <h2>3. Utilisation du site</h2>
        <p>Le site flowgraines.fr est destiné à fournir des informations concernant l'ensemble des activités de l'entreprise. Aurélien Bessonnet s'efforce de fournir des informations aussi précises que possible. Toutefois, il ne pourra être tenu responsable des omissions, des inexactitudes et des carences dans la mise à jour, qu'elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations.</p>

        <h2>4. Limitation de responsabilité</h2>
        <p>L'utilisateur utilise le site à ses seuls risques. En aucun cas, ni Aurélien Bessonnet, ni les hébergeurs, ne pourront être tenus responsables des dommages directs ou indirects, et notamment des préjudices matériels, pertes de données ou de programme, préjudices financiers, résultant de l'accès ou de l'utilisation de ce site ou de tous sites qui lui sont liés.</p>

        <h2>5. Contact</h2>
        <p>Pour toute question ou demande d'assistance, les utilisateurs peuvent contacter Aurélien Bessonnet par l'adresse électronique suivante : contact@flowgraines.mozmail.com</p>
      </div>
  );
}

export default Cgu;
