import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Footer from './Footer.js';
import Navbar from './Navbar.js';
import Connexion from './PanelConnexion.js';
import withAuth from './withAuth.js';
import Verification from './Verification.js';
import ValidationEmail from './Validation_email.js';
import './style.css';

import HomePage from './HomePage.js';
import MentionsLegales from './MentionsLegales.js';
import Cgv from './CGV.js';
import Cgu from './CGU.js';

import ListeCours from './ListeCours.js';
import FormationSaimer from './FormationSaimer.js';
import IntroductionSaimer from './formationSaimer/introduction.js';
import Chapitre1Saimer from './formationSaimer/chapitre1.js';
import Chapitre2Saimer from './formationSaimer/chapitre2.js';
import Chapitre3Saimer from './formationSaimer/chapitre3.js';
import Chapitre4Saimer from './formationSaimer/chapitre4.js';
import Chapitre5Saimer from './formationSaimer/chapitre5.js';
import Chapitre6Saimer from './formationSaimer/chapitre6.js';
import Chapitre7Saimer from './formationSaimer/chapitre7.js';
import Chapitre8Saimer from './formationSaimer/chapitre8.js';
import Chapitre9Saimer from './formationSaimer/chapitre9.js';
import Chapitre10Saimer from './formationSaimer/chapitre10.js';
import Chapitre11Saimer from './formationSaimer/chapitre11.js';
import Chapitre12Saimer from './formationSaimer/chapitre12.js';

import FormationCroissance from './FormationCroissance.js';
import Chapitre1Croissance from './formationCroissance/chapitre1.js';
import Chapitre2Croissance from './formationCroissance/chapitre2.js';
import Chapitre3Croissance from './formationCroissance/chapitre3.js';
import Chapitre4Croissance from './formationCroissance/chapitre4.js';
import Chapitre5Croissance from './formationCroissance/chapitre5.js';

import ConditionalBackgroundWrapper from './animations/conditionalBackgroundWrapper.js';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useUI } from './UIContext.js';
import { useAuth } from './AuthContext.js';

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }, 100); 

    return () => clearTimeout(timer);
  }, [location]);

  return null;
}

const adjustTextSize = (adjustment) => {
  const html = document.documentElement;
  const currentFontSize = window.getComputedStyle(html).fontSize;
  const currentSize = parseFloat(currentFontSize); // Extrait la valeur numérique

  if (adjustment === 'increase') {
    html.style.fontSize = `${currentSize * 1.1}px`; // Augmente de 10%
  } else if (adjustment === 'decrease') {
    html.style.fontSize = `${currentSize * 0.9}px`; // Réduit de 10%
  }
};

const Accessibilite = () => {
  const location = useLocation();
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [bgColor, setBgColor] = useState('#6b44aa');

  const routesWithoutAccessibilite = ['/', '/listeCours', '/mentions-legales', '/conditions-generales-de-vente', '/conditions-generales-d-utilisation'];
  const withAccessibilite = !routesWithoutAccessibilite.includes(location.pathname);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    const homeElement = document.querySelector('.home');
    if (homeElement) {
      document.body.classList.toggle('dark-mode', !isDarkMode);
      homeElement.style.backgroundColor = bgColor; // Applique la couleur de fond actuelle
    }
  };

  const changeBgColor = (color) => {
    setBgColor(color);
    const homeElement = document.querySelector('.home');
    if (homeElement) {
      homeElement.style.backgroundColor = color; // Change la couleur de fond de l'élément .home
    }
  };

  useEffect(() => {
    const homeElement = document.querySelector('.home');
    if (homeElement) {
      homeElement.style.backgroundColor = bgColor; // S'assure que la couleur de fond initiale est appliquée à .home
    }
  }, [bgColor]);

  if (!withAccessibilite) return null;

  return (
    <div className="accessibilite">
      <div className="accessibility-button-container">
        <button 
          onClick={toggleDarkMode} 
          className={`accessibility-button ${!isDarkMode ? 'dark-mode-button' : 'light-mode-button'}`} 
          aria-label="Basculer le mode sombre"
        ></button>

        <div className="color-options">
          <div onClick={() => changeBgColor('#12396d')} className="color-square" style={{ backgroundColor: '#12396d' }}></div>
          <div onClick={() => changeBgColor('#6b44aa')} className="color-square" style={{ backgroundColor: '#6b44aa' }}></div>
        </div>
      </div>
      <button onClick={() => adjustTextSize('increase')} className="accessibility-button text-larger-button" aria-label="Agrandir la taille du texte"></button>
      <button onClick={() => adjustTextSize('decrease')} className="accessibility-button text-smaller-button" aria-label="Réduire la taille du texte"></button>
    </div>
  );
};

const TitleAndDescription = () => {
  const location = useLocation();
  const { updateTitle, updateMetaDescription, updateSocialMeta } = useAuth();

  useEffect(() => {
    const path = location.pathname;
    if (path === '/') {
      updateTitle("Flowgraines - plateforme de cours de développement personnel");
      updateMetaDescription("Découvrez des cours de développement personnel profonds et améliorez les fondements de votre vie.");
      updateSocialMeta("Flowgraines - plateforme de cours de développement personnel", "Découvrez des cours de développement personnel profonds et améliorez les fondements de votre vie.", "https://flowgraines.fr/pictures/Flowgraines.png");
    } else if (path.startsWith('/formationSaimer')) {
      updateTitle("Flowgraines - Cours S'aimer soi-même");
      updateMetaDescription("Participez à notre cours pour apprendre à s'aimer et trouver le bonheur intérieur.");
      updateSocialMeta("Flowgraines - Cours S'aimer soi-même", "Participez à notre cours pour apprendre à s'aimer soi-même et trouver le bonheur intérieur.", "https://flowgraines.fr/pictures/Flowgraines.png");
    } else if (path.startsWith('/formationCroissance')) {
      updateTitle("Flowgraines - Cours Croissance Personnelle");
      updateMetaDescription("Explorez le cours sur la croissance personnelle et redécouvrez votre potentiel.");
      updateSocialMeta("Flowgraines - Cours Croissance Personnelle", "Explorez le cours sur la croissance personnelle et redécouvrez votre potentiel.", "https://flowgraines.fr/pictures/Flowgraines.png");
    } else {
      updateTitle("Flowgraines - Open your heart and your mind");
      updateMetaDescription("Rejoignez Flowgraines pour ouvrir votre cœur et votre esprit à de nouvelles possibilités.");
      updateSocialMeta("Flowgraines - Open your heart and your mind", "Rejoignez Flowgraines pour ouvrir votre cœur et votre esprit à de nouvelles possibilités.", "https://flowgraines.fr/pictures/Flowgraines.png");
    }
  }, [updateTitle, updateMetaDescription, updateSocialMeta, location]);
};

function App() {
  const { currentUser, userData, loadUserData } = useAuth();

  useEffect(() => {
    if (currentUser && !userData) {
      loadUserData(currentUser.uid).catch(error => {
        console.error("Erreur lors du chargement des données utilisateur", error);
      });
    }
  }, [currentUser, userData, loadUserData]);

  const ProtectedFormationSaimer = withAuth(FormationSaimer);
  const ProtectedIntroductionSaimer = withAuth(IntroductionSaimer);
  const ProtectedChapitre1Saimer = withAuth(Chapitre1Saimer);
  const ProtectedChapitre2Saimer = withAuth(Chapitre2Saimer);
  const ProtectedChapitre3Saimer = withAuth(Chapitre3Saimer);
  const ProtectedChapitre4Saimer = withAuth(Chapitre4Saimer);
  const ProtectedChapitre5Saimer = withAuth(Chapitre5Saimer);
  const ProtectedChapitre6Saimer = withAuth(Chapitre6Saimer);
  const ProtectedChapitre7Saimer = withAuth(Chapitre7Saimer);
  const ProtectedChapitre8Saimer = withAuth(Chapitre8Saimer);
  const ProtectedChapitre9Saimer = withAuth(Chapitre9Saimer);
  const ProtectedChapitre10Saimer = withAuth(Chapitre10Saimer);
  const ProtectedChapitre11Saimer = withAuth(Chapitre11Saimer);
  const ProtectedChapitre12Saimer = withAuth(Chapitre12Saimer);

  const ProtectedVerification = withAuth(Verification);

  const { isLoginModalOpen } = useUI();

  return ( 
    <Router>
      <TitleAndDescription />
      <div className="home">
        <ConditionalBackgroundWrapper>
        <ToastContainer />
        <ScrollToTop />
        <Navbar />
        {isLoginModalOpen && <Connexion />}

        <Accessibilite />

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/mentions-legales" element={<MentionsLegales />} />
          <Route path="/conditions-generales-de-vente" element={<Cgv />} />
          <Route path="/conditions-generales-d-utilisation" element={<Cgu />} />

          <Route path="/verification" element={<ProtectedVerification />} />
          <Route path="/validation_email" element={<ValidationEmail />} />

          <Route path="/listeCours" element={<ListeCours />} />
          <Route path="/formationSaimer" element={<ProtectedFormationSaimer />} />
          <Route path="/formationSaimer/introduction" element={<ProtectedIntroductionSaimer />} />
          <Route path="/formationSaimer/chapitre1" element={<ProtectedChapitre1Saimer />} />
          <Route path="/formationSaimer/chapitre2" element={<ProtectedChapitre2Saimer />} />
          <Route path="/formationSaimer/chapitre3" element={<ProtectedChapitre3Saimer />} />
          <Route path="/formationSaimer/chapitre4" element={<ProtectedChapitre4Saimer />} />
          <Route path="/formationSaimer/chapitre5" element={<ProtectedChapitre5Saimer />} />
          <Route path="/formationSaimer/chapitre6" element={<ProtectedChapitre6Saimer />} />
          <Route path="/formationSaimer/chapitre7" element={<ProtectedChapitre7Saimer />} />
          <Route path="/formationSaimer/chapitre8" element={<ProtectedChapitre8Saimer />} />
          <Route path="/formationSaimer/chapitre9" element={<ProtectedChapitre9Saimer />} />
          <Route path="/formationSaimer/chapitre10" element={<ProtectedChapitre10Saimer />} />
          <Route path="/formationSaimer/chapitre11" element={<ProtectedChapitre11Saimer />} />
          <Route path="/formationSaimer/chapitre12" element={<ProtectedChapitre12Saimer />} />

          <Route path="/formationCroissance" element={<FormationCroissance />} />
          <Route path="/formationCroissance/chapitre1" element={<Chapitre1Croissance />} />
          <Route path="/formationCroissance/chapitre2" element={<Chapitre2Croissance />} />
          <Route path="/formationCroissance/chapitre3" element={<Chapitre3Croissance />} />
          <Route path="/formationCroissance/chapitre4" element={<Chapitre4Croissance />} />
          <Route path="/formationCroissance/chapitre5" element={<Chapitre5Croissance />} />
        </Routes>

        <Footer />
        </ConditionalBackgroundWrapper>
      </div>
    </Router>
  );
}

export default App;
