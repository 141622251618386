import { useRef, useEffect } from 'react';
import { useSpring } from '@react-spring/web';
import { useLocation } from 'react-router-dom';

const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;
  return function() {
    const context = this;
    const args = arguments;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      if (lastFunc) cancelAnimationFrame(lastFunc);
      lastFunc = requestAnimationFrame(function() {
        if ((Date.now() - lastRan) >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      });
    }
  }
}

const useAnimatedLine = () => {
  const ref = useRef();
  const [animationStyle, api] = useSpring(() => ({
    opacity: 0,
    transform: 'translateY(75vh)',
    config: { tension: 120, friction: 14 }
  }));

  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      requestAnimationFrame(() => {
        if (ref.current) {
          const targetElement = ref.current.querySelector('.animatable');
          if (targetElement) {
            const rect = targetElement.getBoundingClientRect();
            const screenHeight = window.innerHeight;
            const elementMiddle = rect.top + rect.height / 2;
    
            let translateY, opacity;
    
            const distanceFromMiddle = Math.abs(screenHeight / 2 - elementMiddle) / (screenHeight / 2);
            opacity = Math.max(0, Math.min(1, 1 - distanceFromMiddle)); // S'assure que opacity est entre 0 et 1
    
            // Calcule translateY avec une limite pour éviter des valeurs extrêmes
            const maxTranslateY = 150; // La valeur maximale de déplacement en pixels
            translateY = Math.max(-maxTranslateY, Math.min(maxTranslateY, distanceFromMiddle * 150 * (elementMiddle < screenHeight / 2 ? -1 : 1)));
    
            api.start({ opacity, transform: `translateY(${translateY}px)` });
          }
        }
      });
    };

  const throttledHandleScroll = throttle(handleScroll, 10); 

  window.addEventListener('scroll', throttledHandleScroll);

  return () => {
    window.removeEventListener('scroll', throttledHandleScroll);
  };

}, [api, location]);

return [animationStyle, ref];
};

export default useAnimatedLine;