import React from 'react';
import { Link } from 'react-router-dom';
import { animated } from '@react-spring/web';
import useAnimatedLine from '../animations/animatedLine.js';
import LoadingGif from '../animations/loadingGif.js';
import { useAnimatedTrail } from '../animations/animatedTrail.js'

function Chapitre2Saimer() {
  const { animatedItems, ref } = useAnimatedTrail([
      <span>Attentes</span>,
      <span>Auto-sabotage</span>,
      <span>Dévalorisation</span>,
    ]);

  const [animationStyle1, ref1] = useAnimatedLine();
  const [animationStyle2, ref2] = useAnimatedLine();
  const [animationStyle3, ref3] = useAnimatedLine();
  const [animationStyle4, ref4] = useAnimatedLine();
  const [animationStyle5, ref5] = useAnimatedLine();
  const [animationStyle6, ref6] = useAnimatedLine();
  const [animationStyle7, ref7] = useAnimatedLine();
  const [animationStyle8, ref8] = useAnimatedLine();
  const [animationStyle9, ref9] = useAnimatedLine();

  return (
      <div className="content">

        <h1 className='chapitre'>Chapitre 2 : Notre création</h1>
        
        <p>
          Le manque fait partie du monde de la séparation. C'est à la fois une réalité et en même temps une illusion. Regardez comme la nature est abondante. 
          Vous pouvez bien sûr apercevoir des arbres sous des conditions précaires mourir par manque d'eau... Mais globalement, sans l'intervention de l'Homme, les échanges ne manquent pas : tout nourrit tout.
          Il n'y aura pas d'élément naturel qui ne servira à rien. Les échangent s'effectuent à tous niveaux. Et comme dirait Lavoisier “Rien ne se crée, rien ne se perd, tout se transforme”. 
          Ainsi la nature est l'exemple même de ce qui est harmonie et abondance.
        </p>
        
        <p>
          En fait, pour ce chapitre, j'aimerais que vous perceviez à quel point vous avez de l'abondance dans votre vie. Cette perception est un facteur clé qui permet de mieux apprécier les choses de notre vie. Le manque nourrie la peur, et la peur nous distancie du fait d'être réellement en paix, dirait notre petit maître Yoda intérieur (le mien en tout cas ^^)).
        </p>

        <animated.div className="animated-line" style={animationStyle1} ref={ref1}>
          <p>Regardez en fait, là où vous avez créé dans votre vie <span className="gold">une abondance particulière.</span></p>
          <div className="perso_talking">
            <LoadingGif
              src="/pictures/moiteacher3.gif"
              placeholder="/pictures/moiteacher3.png"
              alt="cours développement personnel"
            />
          </div>
        </animated.div>

        <animated.div className="animated-line" style={animationStyle2} ref={ref2}>
          <p className="animatable">
            Chacun a des ressources pour lesquelles il ou elle s'est donné la possibilité de les avoir en abondance. Soyez attentif, certains se seront donnés la possibilité d'avoir pléthore de matériel informatique, d'autres auront beaucoup de relations, et d'autres encore, se seront permis d'avoir par exemple de nombreux outils pour leurs créations (travail, art, ...).
          </p>
        </animated.div>
        
        <animated.div className="animated-line" style={animationStyle3} ref={ref3}>
          <p className="animatable">
          Ça peut être matériel ou bien immatériel (comme les liens dans les réseaux, de l'affection-proximité familiale/amicale, ou encore l'argent qui peut être perçu comme les deux en même temps).
          </p>
        </animated.div>

        <p>
        Un exercice que je vous propose est donc de lister sur papier ce que vous percevez avoir en abondance. Vous pouvez lister des choses comme vu précédemment, comme des choses très simples (qui ont tout à fait leur importance) : une abondance de stylos, de livres, ou bien de décorations par exemple. Réfléchissez, vous en avez AU moins quelques-uns.
        </p>

        <animated.div className="animated-line" style={animationStyle4} ref={ref4}>
          <p className="underline animatable">
            L'objectif est de voir que cette abondance est le fruit de votre ouverture naturelle à croire que vous en avez le droit.
          </p>
        </animated.div>

        <p>
          Il y a, lorsqu'on explore ce qui est lié à ce sujet, la découverte de la fameuse “Loi d'attraction”. Une loi qui exprime le fait que tout est vibration dans l'univers et que chaque élément s'attire selon celle-ci. <br /><br />
          Le négatif attire le négatif, et de même pour le positif. Alors, on a donc socialement un effet miroir par rapport aux autres.
          Je comprends pour ma part que cette loi est une loi de l'univers, et qu'elle décrit en parti le fonctionnement de l'amour. <br /><br />Elle ne figure pas dans des écrits scientifiques actuels mais vous pouvez voir ce type de connaissance (des lois universelles) 
          plutôt dans des écrits anciens ou auprès de personnes qui partagent aujourd'hui de telles connaissances, car elles sont dans cette aspiration de leur vérité et de leur profondeur. 
          Ces lois qui agissent telles une loi de la gravité, seront connues plus tard d'une façon populaire, mais pour l'instant, il n'y a que ceux qui cherchent qui trouvent...<br />
        </p>

        <animated.div className="animated-line" style={animationStyle5} ref={ref5}>
          <p className="animatable">
          Ce qu'il faut comprendre c'est que : comme toute loi, c'est quelque chose <span className="underline">qui agit sans qu'on n'y fasse quoi que ce soit.</span><br /> Les pensées créent des ressentis, et ce que l'on ressent attire des choses qui sont du même ordre. <br />
          </p>
        </animated.div>

        <p>
          Maintenant, certaines personnes pensent pouvoir tout attirer dans leur vie, c'est vrai et faux en même temps. Vous n'êtes pas forcément venu dans cette vie pour gagner au loto, et cela ne vous arrangerait pas même si vous le croyez. 
          Je n'aime pas cultiver une attitude de victime. Ça a été pourtant le cas comme beaucoup, sinon tout le monde serait joyeux et avec leur vie en main. On “aime” parfois faire notre caliméro. Ça nous fait croire qu'on est "puissant" dans notre petitesse. Ça nous rassure de nous sentir petit. C'est comme une déresponsabilisation face à la vérité du fait d'être créateur. Nous avons peur de notre force. En fait, nous sommes bien plus que ce qu'on croit, mais croire le contraire arrange l'identification et l'attachement à ce petit soi.<br /><br />
        </p>
        
        <p>
        Une belle métaphore par rapport à nos créations est l'image des graines qu'on plante.<br /><br />
        Il y a deux façons d'aborder ce que l'on crée dans notre vie. La première est le schéma : <br />
        </p>
        
        <div ref={ref} className="container-trail"> 
          {animatedItems}
        </div>

        <p> <br /><br />
        Voilà comment elle se déroule : 
        </p>
        
        {/*<div className="block_before_anim" />*/}
        
        <p>
          Je plante ma graine (mon idée) dans le sol (la matière, la vie).
        </p>
        <animated.div className="animated-line" style={animationStyle6} ref={ref6}>
          <div className="image">
          <LoadingGif
            src="/pictures/graine_sol.gif"
            placeholder="/pictures/soil.png"
            alt="cours développement personnel"
          />
          </div>
        </animated.div>
        
        <animated.div className="animated-line" style={animationStyle7} ref={ref7}>
          <p className="animatable">
            Je m'attends à des résultats plutôt immédiats. Mais, bien sûr, comme je suis en attente, je ne suis pas disponible pour chaque pas pour la concrétisation de l'idée. 
          </p>
        </animated.div>
        
        <animated.div className="animated-line" style={animationStyle8} ref={ref8}>
          <p className="animatable">
            Je commence à ressentir des choses comme de la frustration à force d'essayer de mener ma barque sans ma guidance intérieure (intuition, envies, autres idées).
          </p>
        </animated.div>

        <animated.div className="animated-line" style={animationStyle9} ref={ref9}>
          <p className="animatable">
            Ensuite, je peux trouver des excuses au Pourquoi ça ne fonctionne pas. Tu en viens même à remettre en question l'idée (qui était peut-être très bien).
          </p>
        </animated.div>
        <div className="perso_talking">
          <img src="/pictures/perso1_chap2.png" alt="cours développement personnel" />
        </div>
       
        <p>
          Puis, tu peux aussi et surtout te juger et croire que TU es le problème.
          Et au fur à mesure que tu as eu des projets et des idées que tu n'as pas suivi en étant flexible et à l'écoute de toi, tu as commencé à t'identifier à ce "moi" qui ne fonctionne pas bien.<br /><br /><br />
          <span className="underline">Il y a la deuxième façon d'aborder notre création :</span> <br /><br />
          Déterrer la graine toutes les vingt minutes pour savoir si elle grandit n'arrange rien, par conséquent, il est nécessaire d'être patient et confiant. Il y a la compréhension d'une loi de base à avoir qui est le fait que <span className="gold">planter une graine et l'entretenir régulièrement est de facto quelque chose qui va donner un résultat quoi qu'il arrive.</span> 
        </p>
        <p>
          <span className='gold bold'>Si tu portes ton attention sur tout ce qui te manque, tu ne fais qu'amplifier ce manque dans ta propre vie. À chaque fois que tu te concentres sur tout ce qui ne va pas, tout ce qui pourrait mal aller dans le futur, 
          tu fais grandir une réalité que tu ne désires pas.</span>
        </p>
        <p>
          Quoi qu'il se passe, <b>si j'entretiens un projet, il fleurira d'une manière ou d'une autre. </b> L'état d'esprit sain a avoir est donc <span className="underline">cette confiance-ci et le fait de se concentrer sur ce qui me met en joie et ce qui me porte dans la vie, à travers des idées et des actions qui sont alignées avec mes valeurs.</span><br />
          Nous sommes parfaits au fond !<br /><br /> Acceptons de vivre l'instant, de pouvoir jouer, et concrétiser avec ambition même si celle-ci n'est pas l'ambition sociétale qu'on peut vouloir nous vendre.<br /><br />
        </p>

        <p className="bold">Une réflexion intéressante sur notre monde mental :</p>

        <p>J'ai parfois l'image de notre intériorité comme un <span className="gold">sanctuaire</span>, un lieu qu'il est bon d'entretenir et de préserver. C'est de là que nous préservons une certaine <span className="underline">"conscience"</span>, je vous explique pourquoi.</p>

        <p>On peut voir ce sanctuaire comme un <span className="gold">jardin</span>, qui nécessite de l'entretien. Avec le temps, sans cet entretien, des mauvaises herbes ou des plantes indésirables peuvent apparaître, tandis que d'autres peuvent dépérir.</p>

        <p>L'idée du sanctuaire est que des pensées traversent notre environnement mental et nous avons comme des <span className="bold">gardes à l'entrée</span> de ce sanctuaire. Si ces gardes ne sont pas entraînés à distinguer les bonnes des mauvaises pensées, ils vont finir par laisser entrer ces pensées parasites, comme des mauvaises herbes. Plus nous avons de mal à maîtriser nos pensées, plus notre sanctuaire intérieur devient désordonné et affecté. Le jour où nous avons assez de présence à nous-même, nous saurons balayer ces pensées en dehors.</p>

        <p>Plus nous réagissons aux pensées, moins nous en sommes conscients. Et moins nous en sommes conscients, plus ces pensées peuvent s'installer dans notre sanctuaire. Mais avec du <span className="underline">travail</span> et de la <span className="underline">mise en lumière</span>, les pensées non souhaitées sortent, et les gardes apprennent à ne plus les laisser revenir.</p>

        <p>Un jardin bien entretenu, sans déchet ou autre, est un lieu qui apporte beaucoup à celui qui en prend soin. <br /><br /></p>
        
        <div className="long-video-container">
          <iframe
            src="https://www.youtube.com/embed/jQgxKs830S4?rel=0" 
            frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            style={{ width: '100%', height: '100%' }}
            title="exercice sur sa création">
          </iframe>
        </div>

        <p><br /><br /></p>
        <div className="button">
          <Link to="/formationSaimer" className="sommaire-button">Retour au sommaire</Link>
        </div>
        
      </div>
  );
}

export default Chapitre2Saimer;
