import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext.js'; 

function withAuth(WrappedComponent) {
  return function(props) {
    const navigate = useNavigate();
    const { currentUser } = useAuth(); // Utilisation de currentUser pour déterminer si l'utilisateur est connecté

    React.useEffect(() => {
      // Redirige vers la page d'accueil si currentUser est null (pas connecté)
      if (!currentUser) {
        navigate('/');
      }
    }, [currentUser, navigate]);

    // Rend le composant enveloppé seulement si currentUser est non-null
    return currentUser ? <WrappedComponent {...props} /> : null;
  };
}

export default withAuth;
