import React from 'react';

function MentionsLegales() {

  return (
      <div className="content ">
         <h1>Mentions Légales</h1>
          <p>Le présent site, accessible à l'URL <a href="http://flowgraines.fr">flowgraines.fr</a>, est édité par :</p>
          <p><span className="bold">Aurélien Bessonnet</span>, résidant au 18 place de la Fraternité, 78280 Guyancourt, France.</p>
          <p>Téléphone : <span className="bold">06.47.83.62.21</span></p>
          <p>Adresse électronique : <a href="mailto:contact@flowgraines.mozmail.com" className="bold">contact@flowgraines.mozmail.com</a></p>
          <p>N° SIRET : <span className="bold">840 890 784 00019</span></p>
          <p>L'hébergement du site est assuré par Firebase, une plateforme appartenant à Google LLC, dont le siège est situé au 1600 Amphitheatre Parkway, Mountain View, CA 94043, États-Unis.</p>
          <p>Responsable de publication : <span className="bold">Aurélien Bessonnet</span></p>
          <p>Pour toute question ou demande d'information concernant le site, ou tout signalement de contenu ou d'activités illicites, l'utilisateur est invité à contacter l'éditeur à l'adresse électronique suivante : <a href="mailto:contact@flowgraines.mozmail.com" className="bold">contact@flowgraines.mozmail.com</a> ou à adresser un courrier recommandé avec accusé de réception à Aurélien Bessonnet à l'adresse suivante : 18 place de la Fraternité, 78280 Guyancourt, France.</p>
          <h2>CNIL</h2>
          <p>Aurélien Bessonnet s'engage à ce que la collecte et le traitement de vos données, effectués à partir du site <a href="http://flowgraines.fr" className="bold">flowgraines.fr</a>, soient conformes au règlement général sur la protection des données (RGPD) et à la loi Informatique et Libertés. Chaque utilisateur dispose d'un droit d'accès, de rectification, d'opposition, de suppression et de portabilité des données le concernant, en effectuant sa demande écrite et signée, accompagnée d'une preuve d'identité.</p>
          <p>La demande doit être adressée à l'adresse électronique : <a href="mailto:contact@flowgraines.mozmail.com" className="bold">contact@flowgraines.mozmail.com</a> ou par courrier postal à l'adresse mentionnée ci-dessus.</p>
      </div>
  );
}

export default MentionsLegales;
