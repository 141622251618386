import React from 'react';

function Cgv() {

  return (
      <div className="content">
        <h1>Conditions Générales de Vente (CGV)</h1>

        <h2>1. Objet</h2>
        <p>Les présentes Conditions Générales de Vente (CGV) régissent la vente de cours en ligne ("le service") proposés sur le site flowgraines.fr. Elles sont accessibles à tout moment sur le site et prévaudront, le cas échéant, sur toute autre version ou tout autre document contradictoire.</p>

        <h2>2. Services proposés</h2>
        <p>Le site flowgraines.fr propose des cours en ligne dans le domaine du développement personnel. Ces cours sont accessibles via un paiement unique et permettent un accès à vie au contenu acheté.</p>

        <h2>3. Prix</h2>
        <p>Les cours sont proposés en euros (€). Le prix applicable est celui affiché sur le site au moment de la commande. Tous les prix sont indiqués clairement sur le site et incluent toutes les taxes applicables.</p>

        <h2>4. Processus d'achat</h2>
        <p>Le processus d'achat sur flowgraines.fr est sécurisé par Stripe. Pour acheter un cours, l'utilisateur doit sélectionner le cours de son choix, et procéder au paiement par carte bancaire. Une fois le paiement validé, l'accès au cours est immédiatement disponible.</p>

        <h2>5. Modalités de paiement</h2>
        <p>Le paiement est réalisable par carte bancaire via Stripe. Aucun autre mode de paiement n'est accepté.</p>

        <h2>6. Restrictions d'accès</h2>
        <p>Les cours proposés sont des produits virtuels, disponibles mondialement sans restrictions géographiques. Il est néanmoins recommandé d'avoir au moins 18 ans pour effectuer un achat, ou d'avoir l'autorisation d'un parent ou tuteur légal.</p>

        <h2>7. Droit de rétractation et remboursements</h2>
        <p>Conformément à la législation de l'Union européenne, les consommateurs ont le droit de se rétracter du contrat d'achat sans donner de motif dans un délai de 14 jours après la date d'achat. Pour exercer ce droit, le client doit notifier sa décision de rétractation à flowgraines.fr via l'adresse contact@flowgraines.mozmail.com. Si le cours n'a pas été consulté, le client recevra un remboursement complet. Dans le cas où le contenu aurait été partiellement consulté, un remboursement partiel, calculé au prorata du contenu consommé, pourra être envisagé.</p>

        <h2>8. Réclamations</h2>
        <p>Toute réclamation doit être adressée à l'adresse électronique contact@flowgraines.mozmail.com. Flowgraines s'engage à répondre et à traiter toutes les réclamations dans les meilleurs délais.</p>

        <h2>9. Garantie</h2>
        <p>Tous les cours vendus sur flowgraines.fr sont couverts par une garantie de satisfaction de 15 jours. Si, pour quelque raison que ce soit, le client n'est pas satisfait du cours acheté, il peut demander un remboursement complet dans les 15 jours suivant l'achat.</p>
      </div>
  );
}

export default Cgv;
