import React from 'react';
import { Link } from 'react-router-dom';

function Chapitre10Saimer() {

  return (
      <div className="content">

        <h1 className='chapitre'>Chapitre 10: Notre regard et le miroir</h1>

        <p>
        Notre regard sur le monde est puissant. On se focalise sur des choses ici et là, et notre perception change.
        Et à force que notre perception change, notre réalité change de même.
        <br />Il y a 6 règles que j'ai écrites pour voir les choses d'une manière saine ou comment voir le bon partout.
        <br />Il ne s'agit pas de se voiler la face, mais de percevoir avec du recul. <br /><br /><br />
        </p>

        <div className="image-container">
          <img src="/pictures/regard_positif/1.png" alt="Première règle sur le respect de ses propres décisions basées sur sa conscience et ses expériences personnelles." />
          <img src="/pictures/regard_positif/2.png" alt="Deuxième règle évoquant l'importance de l'ordre de l'univers et la reconnaissance de nos limites." />
          <img src="/pictures/regard_positif/3.png" alt="Troisième règle sur l'auto-réflexion et le fait de se voir dans les reproches que nous faisons aux autres." />
          <img src="/pictures/regard_positif/4.png" alt="Quatrième règle sur l'amplification de la gratitude et son impact sur la perception des aspects positifs de la vie." />
          <img src="/pictures/regard_positif/5.png" alt="Cinquième règle sur la nature humaine et l'apprentissage à travers les erreurs pour s'améliorer." />
          <img src="/pictures/regard_positif/6.png" alt="Sixième règle sur la perspective d'empathie et de pardon envers autrui, inspirée par l'innocence enfantine." />
        </div>

        <p>
        Notre regard sur le monde est impactant, pour notre entourage comme pour nous-même.
        Un professeur qui juge ses élèves négativement, paradoxalement, ne les élèvera pas. Lorsqu'on voit le potentiel en l'autre, ce dernier le percevra et aura plus la capacité à l'incarner, car désormais il se voit comme : celui qui peut le faire. <br /><br />
        Notre regard nous impacte aussi nous-même : la vision est un sens qui est rapide et qui a tendance à impacter le reste. Si je vois que ma vie n'est pas comme je le souhaite, et que je ressasse (les souvenirs), je m'amène à penser plus à ces schémas qui me conditionnent... ce qui aura comme impact le fait de, potentiellement, me sentir encore dans ceux-ci et de répéter ce qui les engendre.
        Si je perçois la beauté sur mon corps, j'engendre le fait de me sentir bien dans mon corps et tout ce qui peut être la conséquence d'une bonne estime de soi.
        Le regard est une action : je décide ce sur quoi je me focalise. De ce fait, on a vraiment beaucoup d'impact sur ce que l'on nourrit dans notre vie.
        </p>
        <div className="button">
          <Link to="/formationSaimer" className="sommaire-button">Retour au sommaire</Link>
        </div>

      </div>
  );
}

export default Chapitre10Saimer;
