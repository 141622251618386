import React from 'react';
import { Link } from 'react-router-dom';
import YouTubeVideo from "../animations/videoPlayed.js"

function Chapitre9Saimer() {

  return (
      <div className="content">

        <h1 className='chapitre'>Chapitre 9 : Notre direction</h1>

        <p>
        <b>Ne négocie pas avec tes rêves ! </b> 
        Ne vends pas tes rêves aux enchères. On peut avoir tendance à dealer avec soi-même. “Je m'autorise ça. Ça c'est trop. Je suis pas assez pour y accéder...”. Ça devient des objectifs abandonnés car on a trop souffert et car on s'identifie trop à quelqu'un qui n'y arrivera pas.<br />
        Sans s'en rendre compte, on laisse notre potentiel s'endormir parce qu'on est submergés de peurs. Et si je me ridiculisais ? Et si je ratais ? Et si les autres me trouvaient stupide, incapable ou médiocre ?
        <br /><br />
        Ne négocie pas avec tes rêves. On doit pouvoir se  permettre de les acheminer petit à petit dans nos pensées et notre ressenti, puis dans notre corps. Cela n'a pas besoin et cela n'a pas à être parfait. C'est l'action et l'engagement qui nous amène sur un chemin qui nous emmène lui-même dans la bonne direction.<br /><br />
        <br />
        </p>

        <YouTubeVideo videoId="KDyz5qckQCY" />

        <p><br /><br /><br />
        L'attente d'une validation auprès des autres ne nous aide pas. Car c'est l'essence même de la confiance en soi que de savoir qu'on peut se valider tout seul. Attendre la validation de la part d'autrui nous laisse penser : je ne sais pas encore m'écouter vraiment donc je laisse les autres le pouvoir de décider si un choix est mieux pour moi ou non. 
        <br /><br />
        Lorsque tu as pris tes propres décisions, remémore toi : est-ce qu'elles ont été critiquées ? Comment l'as-tu vécu émotionnellement ? <br /><br />
        Ce genre de critique, notamment dans la vie d'un enfant, peut l'amené à ne plus avoir confiance en son pouvoir de décision et à ne plus vouloir se fier à sa voix intuitive. Apprendre fait partie de la vie, et l'enfant a ce besoin naturel d'essayer par lui-même. Essayer, apprendre, et décider pour soi : c'est un ensemble de capacité qui donne la possibilité à une personne d'avancer et de prendre aussi ses responsabilités.<br />
        Lorsqu'une personne a eu trop de dévalorisation, même sans méchanceté réelle, mais par des peurs transmises, elle n'a plus cette confiance qui lui permet de s'auto-valider. 
        </p>

        <p>
        Une fois adulte, on a alors besoin de s'autoriser des actions peut-être plus petites pour reprendre confiance et se permettre par la suite des projets plus vastes.
        La justesse c'est exactement ce qui va te permettre d'évoluer. 
        <br /><br /><br />
        </p>

        <YouTubeVideo videoId="tOfLhYlGIuU" />

        <p><br /><br /><br />
        Si tu veux sauter une étape, et passer plusieurs marches de l'escalier, le résultat c'est que tu vas probablement tomber. 
        Le fait de ne pas être juste pour soi, est quelque chose qui se sent.
        <br /><br /><br />
        </p>

        <div className="button">
          <Link to="/formationSaimer" className="sommaire-button">Retour au sommaire</Link>
        </div>

      </div>
  );
}

export default Chapitre9Saimer;
