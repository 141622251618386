import React from 'react';
import { useLocation } from 'react-router-dom';
import RandomBackgroundWrapper from './randomBackgroundWrapper.js';

const ConditionalBackgroundWrapper = ({ children }) => {
  const location = useLocation();
  
  // Liste des chemins où vous voulez appliquer le fond aléatoire
  const pathsWithBackground = ['/listeCours', '/autrePageAvecFond'];

  const shouldApplyBackground = pathsWithBackground.includes(location.pathname);

  if (shouldApplyBackground) {
    return <RandomBackgroundWrapper>{children}</RandomBackgroundWrapper>;
  }

  return <>{children}</>;
};

export default ConditionalBackgroundWrapper;