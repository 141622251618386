import React, { useState, useEffect } from 'react';
import { animated, useSpring } from '@react-spring/web';
import { useLocation, Link } from 'react-router-dom';
import Parallax from './animations/parallax.js';
import ScrollToTopButton from './animations/scrollToTopButton.js';

import { useUI } from './UIContext.js';
import { useAuth } from './AuthContext.js';

const NavLink = ({ children, to }) => {
  const [styles, api] = useSpring(() => ({ scale: 1, config: { tension: 150, friction: 10 } }));

  return (
    <animated.div
      onMouseEnter={() => api.start({ scale: 1.1 })}
      onMouseLeave={() => api.start({ scale: 1 })}
      style={styles}
    >
      <Link to={to} className="nav-link" style={{ textDecoration: 'none' }}>
        {children}
      </Link>
    </animated.div>
  );
};

const NavButton = ({ children, onClick }) => {
  const [styles, api] = useSpring(() => ({ scale: 1, config: { tension: 150, friction: 10 } }));

  return (
    <animated.div
      onMouseEnter={() => api.start({ scale: 1.1 })}
      onMouseLeave={() => api.start({ scale: 1 })}
      style={styles}
      onClick={onClick}
    >
      <span className="nav-link">
        {children}
      </span>
    </animated.div>
  );
};

const Navbar = () => {
  const { openLoginModal } = useUI(); 
  const { currentUser, logout } = useAuth(); 

  const handleLogout = async () => {
    try {
      await logout();
      // Gérer ici ce qui se passe après la déconnexion si nécessaire
    } catch (error) {
      console.error("Erreur lors de la déconnexion", error);
    }
  };

  const [scrollY, setScrollY] = useState(0);
  const threshold = window.innerHeight * 0.5;
  const navbarStyle = useSpring({
    transform: `translateY(${scrollY > threshold ? '-100%' : '0%'})`, // Remplacez '100' par le seuil de défilement désiré
    opacity: scrollY > threshold ? 0 : 1,
  });

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const location = useLocation();

  const routesWithParallax = ['/', '/formationSaimer', '/formationSaimer/introduction', '/formationCroissance']; 
  const showParallax = routesWithParallax.includes(location.pathname);

  const routesWithoutScrollToTopButton = ['/', '/listeCours']
  const withButton = !routesWithoutScrollToTopButton.includes(location.pathname);

  const baseSommairePath = location.pathname.split('/')[1];
  const withSommaireButton = location.pathname.split('/').length > 2;

  const sommaireLinkStyle = showParallax ? {} : { marginTop: '47px' };

  return (
    <>
      {showParallax && <Parallax />}
      <animated.header style={navbarStyle} className={showParallax ? "header" : "headerColor"}>
        <nav className="navbar">
          <NavLink to="/">Let's start</NavLink>
          <NavLink to="/listeCours">Les programmes</NavLink>
          {currentUser ? (
          <NavButton onClick={handleLogout}>Se déconnecter</NavButton>
        ) : (
          <NavButton onClick={openLoginModal}>Connexion</NavButton>
        )}
        </nav>
      </animated.header>

      {withButton && <ScrollToTopButton />}
          
      {withSommaireButton && <Link to={`/${baseSommairePath}`} className="sommaire-nav" style={sommaireLinkStyle}>Retour au sommaire</Link>}
    </>
  );
};

export default Navbar;