import React, { useState, useEffect, useRef } from 'react';

const LoadingGif = ({ src, placeholder, alt }) => {
  const [gifSrc, setGifSrc] = useState(placeholder); // Commence avec le placeholder
  const imgRef = useRef(null);

  useEffect(() => {
    const currentImg = imgRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setGifSrc(src); // Change le src pour le GIF
            observer.unobserve(currentImg); // Détache l'observer une fois l'image chargée
          }
        });
      },
      {
        root: null, // viewport comme racine
        rootMargin: '0px',
        threshold: 1.0
      }
    );

    if (currentImg) {
      observer.observe(currentImg);
    }

    // Fonction de nettoyage
    return () => {
      if (currentImg) {
        observer.unobserve(currentImg);
      }
    };
  }, [src]); // Écoute les changements de la source du GIF

  return <img src={gifSrc} alt={alt} ref={imgRef} className="animatable" />;
};

export default LoadingGif;
